import React from "react"

const TipsFromTeam = ({ data, services }) => {
  return (
    <section className={`team-sec ${services && "m-0 surgery-blog-sec"}`}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{data.title}</h2>
            <p className="lead">{data.content}</p>
          </div>
          {data.tips.map(tip => (
            <div className="col col-md-4" key={tip.id}>
              <div className="team-sec-box">
                <a href={tip.url}><img src={tip.image.file.url} alt="#" /></a>
                <div className="lower-blog-card">
                  <a href={tip.url}><h5>{tip.label}</h5></a>
                  <a href={tip.url}><p>{tip.shortDescription}</p></a>
                  <a className="link-btn" href={tip.url}>Read More<svg xmlns="http://www.w3.org/2000/svg" width="22.142" height="14.767" viewBox="0 0 22.142 14.767"><path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward" d="M22,11.534a1,1,0,0,0-.008,1.415l4.676,4.684H8.867a1,1,0,0,0,0,2H26.656L21.98,24.317a1.012,1.012,0,0,0,.008,1.415,1,1,0,0,0,1.407-.008l6.337-6.383h0a1.123,1.123,0,0,0,.208-.315.954.954,0,0,0,.077-.385,1,1,0,0,0-.285-.7L23.4,11.557A.98.98,0,0,0,22,11.534Z" transform="translate(-7.875 -11.252)" fill="#ff0245"></path></svg></a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default TipsFromTeam
